import * as shippingMethodsApi from '@/api/shipping/shippingMethods'

const shippingMethods = {
  state: {
  },
  mutations: {
  },
  actions: {
    getShippingMethods ({ commit }, data) {
      return new Promise((resolve, reject) => {
        shippingMethodsApi.getShippingMethods(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showShippingMethod ({ commit }, data) {
      return new Promise((resolve, reject) => {
        shippingMethodsApi.showShippingMethod(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeShippingMethod ({ commit }, data) {
      return new Promise((resolve, reject) => {
        shippingMethodsApi.storeShippingMethod(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateShippingMethod ({ commit }, data) {
      return new Promise((resolve, reject) => {
        shippingMethodsApi.updateShippingMethod(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateShippingMethodSort ({ commit }, data) {
      return new Promise((resolve, reject) => {
        shippingMethodsApi.updateShippingMethodSort(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default shippingMethods
