import request from '@/utils/request'

export function getShippingMethods (data) {
  return request({
    url: '/api/shipping_methods',
    method: 'post',
    data: data
  })
}

export function showShippingMethod (data) {
  return request({
    url: '/api/shipping_methods/show',
    method: 'post',
    data: data
  })
}

export function storeShippingMethod (data) {
  return request({
    url: '/api/shipping_methods/store',
    method: 'post',
    data: data
  })
}

export function updateShippingMethod (data) {
  return request({
    url: '/api/shipping_methods/update',
    method: 'post',
    data: data
  })
}

export function updateShippingMethodSort (data) {
  return request({
    url: '/api/shipping_methods/update_sort',
    method: 'post',
    data: data
  })
}
