import * as postsApi from '@/api/posts/posts'

const posts = {
  state: {
  },
  mutations: {
  },
  actions: {
    getPosts ({ commit }, data) {
      return new Promise((resolve, reject) => {
        postsApi.getPosts(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showPost ({ commit }, data) {
      return new Promise((resolve, reject) => {
        postsApi.showPost(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storePost ({ commit }, data) {
      return new Promise((resolve, reject) => {
        postsApi.storePost(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updatePost ({ commit }, data) {
      return new Promise((resolve, reject) => {
        postsApi.updatePost(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyPost ({ commit }, data) {
      return new Promise((resolve, reject) => {
        postsApi.destroyPost(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default posts
