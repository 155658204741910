import request from '@/utils/request'

export function getProducts (data) {
  return request({
    url: '/api/products',
    method: 'post',
    data: data
  })
}

export function showProduct (data) {
  return request({
    url: '/api/products/show',
    method: 'post',
    data: data
  })
}

export function storeProduct (data) {
  return request({
    url: '/api/products/store',
    method: 'post',
    data: data
  })
}

export function updateProduct (data) {
  return request({
    url: '/api/products/update',
    method: 'post',
    data: data
  })
}

export function destroyProduct (data) {
  return request({
    url: '/api/products/destroy',
    method: 'post',
    data: data
  })
}
