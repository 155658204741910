import * as productCategoriesApi from '@/api/products/productCategories'

const productCategories = {
  state: {
  },
  mutations: {
  },
  actions: {
    getProductCategories ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productCategoriesApi.getProductCategories(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showProductCategory ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productCategoriesApi.showProductCategory(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeProductCategory ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productCategoriesApi.storeProductCategory(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateProductCategory ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productCategoriesApi.updateProductCategory(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateProductCategorySort ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productCategoriesApi.updateProductCategorySort(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyProductCategory ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productCategoriesApi.destroyProductCategory(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default productCategories
