import * as ordersApi from '@/api/orders/orders'

const orders = {
  state: {
  },
  mutations: {
  },
  actions: {
    getOrders ({ commit }, data) {
      return new Promise((resolve, reject) => {
        ordersApi.getOrders(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showOrder ({ commit }, data) {
      return new Promise((resolve, reject) => {
        ordersApi.showOrder(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateOrder ({ commit }, data) {
      return new Promise((resolve, reject) => {
        ordersApi.updateOrder(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default orders
