import request from '@/utils/request'

export function getShippingFees (data) {
  return request({
    url: '/api/shipping_fees',
    method: 'post',
    data: data
  })
}

export function showShippingFee (data) {
  return request({
    url: '/api/shipping_fees/show',
    method: 'post',
    data: data
  })
}

export function storeShippingFee (data) {
  return request({
    url: '/api/shipping_fees/store',
    method: 'post',
    data: data
  })
}

export function updateShippingFee (data) {
  return request({
    url: '/api/shipping_fees/update',
    method: 'post',
    data: data
  })
}

export function updateShippingFeeRegions (data) {
  return request({
    url: '/api/shipping_fees/update_regions',
    method: 'post',
    data: data
  })
}

export function destroyShippingFee (data) {
  return request({
    url: '/api/shipping_fees/destroy',
    method: 'post',
    data: data
  })
}
