import request from '@/utils/request'

export function getProductSerialNumbers (data) {
  return request({
    url: '/api/products/serial_numbers',
    method: 'post',
    data: data
  })
}

export function showProductSerialNumber (data) {
  return request({
    url: '/api/products/serial_numbers/show',
    method: 'post',
    data: data
  })
}

export function storeProductSerialNumber (data) {
  return request({
    url: '/api/products/serial_numbers/store',
    method: 'post',
    data: data
  })
}

export function batchProductSerialNumber (data) {
  return request({
    url: '/api/products/serial_numbers/batch',
    method: 'post',
    data: data
  })
}

export function updateProductSerialNumber (data) {
  return request({
    url: '/api/products/serial_numbers/update',
    method: 'post',
    data: data
  })
}

export function destroyProductSerialNumber (data) {
  return request({
    url: '/api/products/serial_numbers/destroy',
    method: 'post',
    data: data
  })
}
