import request from '@/utils/request'

export function getProductTags (data) {
  return request({
    url: '/api/products/tags',
    method: 'post',
    data: data
  })
}

export function showProductTag (data) {
  return request({
    url: '/api/products/tags/show',
    method: 'post',
    data: data
  })
}

export function storeProductTag (data) {
  return request({
    url: '/api/products/tags/store',
    method: 'post',
    data: data
  })
}

export function updateProductTag (data) {
  return request({
    url: '/api/products/tags/update',
    method: 'post',
    data: data
  })
}

export function destroyProductTag (data) {
  return request({
    url: '/api/products/tags/destroy',
    method: 'post',
    data: data
  })
}
