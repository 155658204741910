export default {
  search_info: 'Last search at {at}, a total of {count} results (search time: {sec} seconds)',
  delete_note: 'Note: This operation cannot be recovered.',
  delete_msg: 'Are you sure you want to delete <b>{name}</b> ?',

  pick_a_date_or_date_range: 'Pick a date or date range',

  incorrect_email_or_password: 'Incorrect username or password',
  wrong_format: 'Wrong format',
  mobile_number_has_been_registered: 'Mobile number has been registered',
  try_again_later: 'Try again later',
  the_verification_code_is_incorrect_or_expired: 'The verification code is incorrect or expired',

  sun: 'Sun',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thur',
  fri: 'Fri',
  sat: 'Sat',

  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  may: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',

  seconds: 'Seconds',
  minutes: 'Minutes',
  hours: 'Hours',
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
  years: 'Years',

  second: 'Second',
  minute: 'Minute',
  hour: 'Hour',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year',

  on: 'On',
  off: 'Off',
  login: 'Log in',
  logout: 'Log out',
  login_to_your_account: 'Log in to your account.',
  forgot_password: 'Forgot password ?',
  lang: 'Language',
  main: 'Main',
  dashboard: 'Dashboard',
  profile: 'Profile',
  name: 'Name',
  email: 'Email',
  calling_code: 'Calling code',
  phone: 'Phone',
  cellphone: 'Cellphone',
  telephone: 'Telephone',
  mobile_number: 'Mobile number',
  username: 'Username',
  password: 'Password',
  change_password: 'Change password',
  current_password: 'Current password',
  new_password: 'New password',
  repeat_password: 'Repeat password',
  generate_password_msg: 'Click the button on the right to generate a random password',
  leave_blank_if_not_modified: 'Leave blank if not modified',
  edit: 'Edit',
  save: 'Save',
  create: 'Create',
  read: 'Read',
  update: 'Update',
  delete: 'Delete',
  ok: 'OK',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  enable: 'Enable',
  disable: 'Disable',
  visible: 'Visible',
  invisible: 'Invisible',
  action: 'Action',
  details: 'Details',
  none: 'None',
  please_select_a: 'Please select a ',
  search_a: 'Search a ',
  change_avatar: 'Change avatar',
  created_successfully: 'Created successfully',
  updated_successfully: 'Updated successfully',
  uploaded_successfully: 'Uploaded successfully',
  deleted_successfully: 'Deleted successfully',
  create_time: 'Create time',
  update_time: 'Update time',
  last_update_time: 'Last update time',
  last_enable_time: 'Last enable time',
  last_status_change_time: 'Last status change time',
  last_used_time: 'Last used time',
  last_data_time: 'Last data time',
  receive_time: 'Receive time',
  required_fields_cannot_be_empty: 'Required fields cannot be empty',
  management: 'Management',
  manager: 'Manager',
  settings: 'Settings',
  system_management: 'System management',
  organization: 'Organization',
  roles: 'Permission',
  role_groups: 'Permission groups',
  users: 'Users',
  search: 'Search',
  search_mode: 'Search mode',
  create_user: 'Create user',
  edit_user: 'Edit user',
  user_name: 'User name',
  create_role_group: 'Create permission group',
  edit_role_group: 'Edit permission group',
  set_roles: 'Set permissions',
  permission_denied: 'Permission denied',
  title: 'Title',
  preview: 'Preview',
  content: 'Content',
  description: 'Description',
  companies: 'Companies',
  company: 'Company',
  website_management: 'Website management',
  business_management: 'Business management',
  posts: 'Posts',
  posts_management: 'Posts management',
  post: 'Post',
  create_post: 'Create post',
  edit_post: 'Edit post',
  delete_post: 'Delete post',
  banner: 'Banner',
  top: 'Top',
  images: 'Images',
  image: 'Image',
  upload_image: 'Upload image',
  image_crop: 'Image crop',
  drag_image_msg: 'Click the image and drag it vertically to change the order',
  drag_text_msg: 'Click the text and drag it vertically to change the order',
  products: 'Products',
  products_management: 'Products management',
  product: 'Product',
  product_images: 'Product images',
  create_product: 'Create product',
  edit_product: 'Edit product',
  delete_product: 'Delete product',
  price: 'Price',
  view_count: 'View count',
  order_count: 'Order count',
  introduction: 'Introduction',
  weight: 'Weight(gram)',
  from_weight: 'From weight(gram)',
  to_weight: 'To weight(gram)',
  max_weight: 'Max weight(gram)',
  total_weight: 'Total weight(gram)',
  inventory: 'Inventory',
  categories: 'Categories',
  category: 'Category',
  parent_category: 'Parent category',
  sub_categories: 'Sub categories',
  create_category: 'Create category',
  edit_category: 'Edit category',
  delete_category: 'Delete category',
  tags: 'Tags',
  tag: 'Tag',
  create_tag: 'Create tag',
  edit_tag: 'Edit tag',
  delete_tag: 'Delete tag',
  serial_numbers: 'Serial numbers',
  serial_number: 'Serial number',
  suffix: 'Suffix',
  low_serial_number: 'Low serial number',
  high_serial_number: 'High serial number',
  batch_number: 'Batch number',
  create_serial_number: 'Create serial number',
  edit_serial_number: 'Edit serial number',
  delete_serial_number: 'Delete serial number',
  registration_status: 'Registration status',
  registered: 'Registered',
  unregistered: 'Unregistered',
  sort: 'Sort',
  color: 'Color',
  options: 'Options',
  option: 'Option',
  parent_option: 'Parent option',
  sub_options: 'Sub options',
  create_option: 'Create option',
  edit_option: 'Edit option',
  delete_option: 'Delete option',
  type: 'Type',
  text: 'Text',
  message: 'Message',
  specifications: 'Specifications',
  specification: 'Specification',
  create_specification: 'Create specification',
  edit_specification: 'Edit specification',
  delete_specification: 'Delete specification',
  orders: 'Orders',
  orders_management: 'Orders management',
  order: 'Order',
  edit_order: 'Edit order',
  incomplete: 'Incomplete',
  pending_payment: 'Pending payment',
  being_prepared: 'Being prepared',
  logistics_in_progress: 'Logistics in progress',
  logistics_complete: 'Logistics Complete',
  completed: 'Completed',
  cancelled: 'Cancelled',
  expired: 'Expired',
  payment_method: 'Payment method',
  shipping_method: 'Shipping method',
  payment_price: 'Payment price',
  order_sn: 'Order #',
  order_status: 'Order status',
  booking_note_sn: 'Booking note #',
  order_price: 'Order price',
  shipping_fee: 'Shipping fee',
  basic_shipping_fee: 'Basic shipping fee',
  shipping: 'Shipping',
  payment: 'Payment',
  shipping_sn: 'Shipping #',
  payment_sn: 'Payment #',
  third_party_sn: 'Third party #',
  recipient_name: 'Recipient name',
  recipient_phone: 'Recipient phone',
  recipient_email: 'Recipient email',
  recipient_zipcode: 'Recipient zip code',
  recipient_address: 'Recipient address',
  store_sn: 'Store ID',
  store_name: 'Store name',
  store_phone: 'Store phone',
  store_address: 'Store address',
  cvs_sn: 'Store shipping number',
  cvs_validation_code: 'Store verification code',
  atm_bank_code: 'ATM Bank code',
  atm_bank_account: 'ATM Bank account',
  atm_expired: 'Expiry date',
  item: 'Item',
  qty: 'Qty',
  price_per_item: 'Price per item',
  subtotal_price: 'Subtotal price',
  coupons: 'Coupons',
  coupon: 'Coupon',
  discount: 'Discount',
  shipping_management: 'Shipping management',
  shipping_methods: 'Shipping methods',
  create_shipping_method: 'Create shipping method',
  edit_shipping_method: 'Edit shipping method',
  shipping_fee_list: 'Shipping fee list',
  create_shipping_fee_list: 'Create shipping fee list',
  edit_shipping_fee_list_regions: 'Edit shipping fee list regions',
  edit_shipping_fee_list: 'Edit shipping fee list',
  delete_shipping_fee_list: 'Delete shipping fee list',
  region: 'Region',
  regions: 'Regions',
  add_rows: 'Add rows',
  size: 'Size(cm)',
  purchase_limit: 'Purchase limit',
  launch_time: 'Launch time',
}
