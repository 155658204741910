import * as productTagsApi from '@/api/products/productTags'

const productTags = {
  state: {
  },
  mutations: {
  },
  actions: {
    getProductTags ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productTagsApi.getProductTags(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showProductTag ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productTagsApi.showProductTag(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeProductTag ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productTagsApi.storeProductTag(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateProductTag ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productTagsApi.updateProductTag(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyProductTag ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productTagsApi.destroyProductTag(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default productTags
