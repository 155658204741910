import axios from 'axios'
import store from '@/store'
import { getSessionName, getSession } from '@/utils/auth'
let baseURL = process.env.VUE_APP_BASEURL || null

const service = axios.create({
  baseURL: baseURL, // api base_url
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    if (store.getters.session) {
      config.headers[getSessionName()] = getSession()
      config.headers['Cache-Control'] = 'no-cache'
    }
    return config
  }, 
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  // response => response,
  response => {
    if (response.data.code === 5000 || response.data.code === 5001) {
      store.dispatch('logout').then(() => {
        location.reload()
      })
    }
    return response.data
  },
  error => {
    // console.log(error) // for debug
    // return Promise.reject(error)
    return error
  }
)

export default service
