import * as productImagesApi from '@/api/products/productImages'

const productImages = {
  state: {
  },
  mutations: {
  },
  actions: {
    getProductImages ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productImagesApi.getProductImages(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateProductImages ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productImagesApi.updateProductImages(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default productImages
