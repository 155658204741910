import * as userApi from '@/api/user/user'
import * as auth from '@/utils/auth'
import i18n from '@/lang/i18n'

const user = {
  state: {
    session: auth.getSession(),
    root: 0,
    roles: ['root'],
    uid: '',
    avatar: 'img/avatars/man.png',
    locale: i18n.locale
  },
  mutations: {
    SET_SESSION: (state, session) => {
      state.session = session
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_UID: (state, uid) => {
      state.uid = uid
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_LANG: (state, locale) => {
      state.locale = locale
      i18n.locale = locale
      localStorage.setItem('locale', locale)
    }
  },
  actions: {
    login ({ commit }, data) {
      return new Promise((resolve, reject) => {
        userApi.login(data).then(res => {
          if (res.code === 2000) {
            commit('SET_SESSION', res.data[0].session)
            auth.setSession(res.data[0].session)
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        userApi.logout().then(res => {
          if (res.code === 2000) {
            commit('SET_SESSION', '')
            commit('SET_ROLES', [])
            commit('SET_UID', '')
            commit('SET_AVATAR', 'img/avatars/man.png')
            commit('SET_LANG', i18n.locale)
            auth.removeSession()
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    checkSession ({ commit }) {
      return new Promise((resolve, reject) => {
        userApi.checkSession().then(res => {
          if (res.code === 2000) {
            commit('SET_ROLES', ['root']/*res.data[0].roles*/)
            commit('SET_UID', res.data[0].id)
            commit('SET_AVATAR', res.data[0].avatar ? res.data[0].avatar : 'img/avatars/man.png')
            if (res.data[0].locale && res.data[0].locale !== i18n.locale) {
              location.reload()
            }
            commit('SET_LANG', res.data[0].locale ? res.data[0].locale : i18n.locale)
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateUserProfile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        userApi.updateUserProfile(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateUserLocale ({ commit }, data) {
      return new Promise((resolve, reject) => {
        userApi.updateUserLocale(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateUserPassword ({ commit }, data) {
      return new Promise((resolve, reject) => {
        userApi.updateUserPassword(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateUserAvatar ({ commit }, data) {
      return new Promise((resolve, reject) => {
        userApi.updateUserAvatar(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    getUsers ({ commit }, data) {
      return new Promise((resolve, reject) => {
        userApi.getUsers(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeUser ({ commit }, data) {
      return new Promise((resolve, reject) => {
        userApi.storeUser(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateUser ({ commit }, data) {
      return new Promise((resolve, reject) => {
        userApi.updateUser(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default user
