import * as productSpecificationsApi from '@/api/products/productSpecifications'

const productSpecifications = {
  state: {
  },
  mutations: {
  },
  actions: {
    getProductSpecifications ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productSpecificationsApi.getProductSpecifications(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showProductSpecification ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productSpecificationsApi.showProductSpecification(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeProductSpecification ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productSpecificationsApi.storeProductSpecification(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateProductSpecification ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productSpecificationsApi.updateProductSpecification(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateProductSpecificationSort ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productSpecificationsApi.updateProductSpecificationSort(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyProductSpecification ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productSpecificationsApi.destroyProductSpecification(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default productSpecifications
