import * as productsApi from '@/api/products/products'

const products = {
  state: {
  },
  mutations: {
  },
  actions: {
    getProducts ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productsApi.getProducts(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showProduct ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productsApi.showProduct(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeProduct ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productsApi.storeProduct(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateProduct ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productsApi.updateProduct(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyProduct ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productsApi.destroyProduct(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default products
