import * as tinymceApi from '@/api/tinymce/tinymce'

const tinymce = {
  state: {
  },
  mutations: {
  },
  actions: {
    uploadFile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        tinymceApi.uploadFile(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    uploadFiles ({ commit }, data) {
      return new Promise((resolve, reject) => {
        tinymceApi.uploadFiles(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    }

  }
}

export default tinymce
