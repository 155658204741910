import request from '@/utils/request'

export function getProductSpecifications (data) {
  return request({
    url: '/api/products/specifications',
    method: 'post',
    data: data
  })
}

export function showProductSpecification (data) {
  return request({
    url: '/api/products/specifications/show',
    method: 'post',
    data: data
  })
}

export function storeProductSpecification (data) {
  return request({
    url: '/api/products/specifications/store',
    method: 'post',
    data: data
  })
}

export function updateProductSpecification (data) {
  return request({
    url: '/api/products/specifications/update',
    method: 'post',
    data: data
  })
}

export function updateProductSpecificationSort (data) {
  return request({
    url: '/api/products/specifications/update_sort',
    method: 'post',
    data: data
  })
}

export function destroyProductSpecification (data) {
  return request({
    url: '/api/products/specifications/destroy',
    method: 'post',
    data: data
  })
}
