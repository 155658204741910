import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/lang/i18n'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const dashboard = () => import('@/views/dashboard/dashboard')
const profile = () => import('@/views/profile')

const organization_user = () => import('@/views/organization/user')

const posts_posts = () => import('@/views/posts/posts')

const products_categories = () => import('@/views/products/categories')
const products_tags = () => import('@/views/products/tags')
const products_products = () => import('@/views/products/products')
const products_serial_numbers = () => import('@/views/products/serialNumbers')

const shipping_methods = () => import('@/views/shipping/methods')
const shipping_fees = () => import('@/views/shipping/fees')

const orders_orders = () => import('@/views/orders/orders')

const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: i18n.t('main'),
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: i18n.t('dashboard'),
          component: dashboard
        },
        {
          path: 'profile',
          name: i18n.t('profile'),
          component: profile
        },
        {
          path: 'organization',
          redirect: '/organization/user',
          name: i18n.t('organization'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'user',
              name: i18n.t('users'),
              component: organization_user,
              meta: {
                role: 'root'
              }
            }
          ]
        },
        {
          path: 'posts',
          redirect: '/posts/posts',
          name: i18n.t('posts_management'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'posts',
              name: i18n.t('posts'),
              component: posts_posts,
              meta: {
                role: 'root'
              }
            }
          ]
        },
        {
          path: 'products',
          redirect: '/products/products',
          name: i18n.t('products_management'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'categories',
              name: i18n.t('categories'),
              component: products_categories,
              meta: {
                role: 'root'
              }
            },
            {
              path: 'tags',
              name: i18n.t('tags'),
              component: products_tags,
              meta: {
                role: 'root'
              }
            },
            {
              path: 'products',
              name: i18n.t('products'),
              component: products_products,
              meta: {
                role: 'root'
              }
            },
            {
              path: 'serial_numbers',
              name: i18n.t('serial_numbers'),
              component: products_serial_numbers,
              meta: {
                role: 'root'
              }
            }
          ]
        },
        {
          path: 'shipping',
          redirect: '/shipping/methods',
          name: i18n.t('shipping_management'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'methods',
              name: i18n.t('shipping_methods'),
              component: shipping_methods,
              meta: {
                role: 'root'
              }
            },
            {
              path: 'fees',
              name: i18n.t('shipping_fee_list'),
              component: shipping_fees,
              meta: {
                role: 'root'
              }
            }
          ]
        },
        {
          path: 'orders',
          redirect: '/orders/orders',
          name: i18n.t('orders_management'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'orders',
              name: i18n.t('orders'),
              component: orders_orders,
              meta: {
                role: 'root'
              }
            }
          ]
        },
      ]
    },
    {
      path: '*',
      redirect: '/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    }
  ]
}

