import * as shippingFeesApi from '@/api/shipping/shippingFees'

const shippingFees = {
  state: {
  },
  mutations: {
  },
  actions: {
    getShippingFees ({ commit }, data) {
      return new Promise((resolve, reject) => {
        shippingFeesApi.getShippingFees(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showShippingFee ({ commit }, data) {
      return new Promise((resolve, reject) => {
        shippingFeesApi.showShippingFee(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeShippingFee ({ commit }, data) {
      return new Promise((resolve, reject) => {
        shippingFeesApi.storeShippingFee(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateShippingFee ({ commit }, data) {
      return new Promise((resolve, reject) => {
        shippingFeesApi.updateShippingFee(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateShippingFeeRegions ({ commit }, data) {
      return new Promise((resolve, reject) => {
        shippingFeesApi.updateShippingFeeRegions(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyShippingFee ({ commit }, data) {
      return new Promise((resolve, reject) => {
        shippingFeesApi.destroyShippingFee(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default shippingFees
