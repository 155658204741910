import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/store/state'
import mutations from '@/store/mutations'
import getters from '@/store/getters'

import user from '@/store/modules/user/user'

import regions from '@/store/modules/regions/regions'

import posts from '@/store/modules/posts/posts'

import products from '@/store/modules/products/products'
import productImages from '@/store/modules/products/productImages'
import productCategories from '@/store/modules/products/productCategories'
import productOptions from '@/store/modules/products/productOptions'
import productSpecifications from '@/store/modules/products/productSpecifications'
import productTags from '@/store/modules/products/productTags'
import productSerialNumbers from '@/store/modules/products/productSerialNumbers'

import shippingMethods from '@/store/modules/shipping/shippingMethods'
import shippingFees from '@/store/modules/shipping/shippingFees'

import orders from '@/store/modules/orders/orders'

// TinyMCE
import tinymce from '@/store/modules/tinymce/tinymce'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    regions,
    posts,
    products,
    productImages,
    productCategories,
    productOptions,
    productSpecifications,
    productTags,
    productSerialNumbers,
    shippingMethods,
    shippingFees,
    orders,

    // TinyMCE
    tinymce,
  },
  state,
  mutations,
  getters
})
