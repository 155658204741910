export default {
  search_info: '最後搜尋於 {at}，共有 {count} 項結果（搜尋時間：{sec}秒）',
  delete_note: '注意：此操作無法恢復',
  delete_msg: '您確定要刪除 <b>{name}</b> 嗎？',

  pick_a_date_or_date_range: '選擇一個日期或日期範圍',

  incorrect_email_or_password: '錯誤的Email或密碼',
  wrong_format: '格式錯誤',
  mobile_number_has_been_registered: '手機號碼已被註冊',
  try_again_later: '稍後再試',
  the_verification_code_is_incorrect_or_expired: '驗證碼不正確或已過期',

  sun: '日',
  mon: '一',
  tue: '二',
  wed: '三',
  thu: '四',
  fri: '五',
  sat: '六',

  jan: '1 月',
  feb: '2 月',
  mar: '3 月',
  apr: '4 月',
  may: '5 月',
  jun: '6 月',
  jul: '7 月',
  aug: '8 月',
  sep: '9 月',
  oct: '10 月',
  nov: '11 月',
  dec: '12 月',

  seconds: '秒',
  minutes: '分',
  hours: '時',
  days: '日',
  weeks: '週',
  months: '月',
  years: '年',

  second: '秒',
  minute: '分',
  hour: '時',
  day: '日',
  week: '週',
  month: '月',
  year: '年',

  on: '開',
  off: '關',
  login: '登入',
  logout: '登出',
  login_to_your_account: '登入您的帳戶',
  forgot_password: '忘記密碼？',
  lang: '語言',
  main: '主頁',
  dashboard: '儀表板',
  profile: '個人資料',
  name: '名稱',
  email: '電子郵件',
  calling_code: '國家代碼',
  phone: '電話',
  cellphone: '手機',
  telephone: '市話',
  mobile_number: '手機號碼',
  username: '使用者名稱',
  password: '密碼',
  change_password: '更改密碼',
  current_password: '當前密碼',
  new_password: '新密碼',
  repeat_password: '重複輸入密碼',
  generate_password_msg: '點擊右方按鈕以生成隨機密碼',
  leave_blank_if_not_modified: '如不修改則留空',
  edit: '編輯',
  save: '儲存',
  create: '新增',
  read: '讀取',
  update: '更新',
  delete: '刪除',
  ok: '確定',
  cancel: '取消',
  yes: '是',
  no: '否',
  enable: '啟用',
  disable: '禁用',
  visible: '可見',
  invisible: '不可見',
  action: '操作',
  details: '詳細資訊',
  none: '無',
  please_select_a: '請選擇一個',
  search_a: '搜尋一個',
  change_avatar: '變更頭像',
  created_successfully: '新增成功',
  updated_successfully: '更新成功',
  uploaded_successfully: '上傳成功',
  deleted_successfully: '刪除成功',
  create_time: '新增時間',
  update_time: '更新時間',
  last_update_time: '最近更新時間',
  last_enable_time: '最近啟用時間',
  last_status_change_time: '最近狀態變更時間',
  last_used_time: '最近使用時間',
  last_data_time: '最近資料時間',
  receive_time: '接收時間',
  required_fields_cannot_be_empty: '必填欄位不能為空',
  management: '管理',
  manager: '管理者',
  settings: '設定',
  system_management: '系統管理',
  organization: '組織',
  roles: '權限',
  role_groups: '權限群組',
  users: '用戶',
  search: '搜尋',
  search_mode: '搜尋模式',
  create_user: '新增用戶',
  edit_user: '編輯用戶',
  user_name: '用戶名稱',
  create_role_group: '新增權限群組',
  edit_role_group: '編輯權限群組',
  set_roles: '設定權限',
  permission_denied: '權限不足',
  title: '標題',
  preview: '預覽',
  content: '內容',
  description: '描述',
  companies: '公司',
  company: '公司',
  website_management: '網站管理',
  business_management: '商業管理',
  posts: '貼文',
  posts_management: '貼文管理',
  post: '貼文',
  create_post: '新增貼文',
  edit_post: '編輯貼文',
  delete_post: '刪除貼文',
  banner: '橫幅',
  top: '置頂',
  images: '圖片',
  image: '圖片',
  upload_image: '上傳圖片',
  image_crop: '圖片裁剪',
  drag_image_msg: '點擊圖片垂直拖曳以變更順序',
  drag_text_msg: '點擊文本垂直拖曳以變更順序',
  products: '產品',
  products_management: '產品管理',
  product: '產品',
  product_images: '產品圖片',
  create_product: '新增產品',
  edit_product: '編輯產品',
  delete_product: '刪除產品',
  price: '價錢',
  view_count: '瀏覽計數',
  order_count: '訂單計數',
  introduction: '簡介',
  weight: '重量(克)',
  from_weight: '從重量(克)',
  to_weight: '到重量(克)',
  max_weight: '最大重量(克)',
  total_weight: '總重量(克)',
  inventory: '庫存',
  categories: '分類',
  category: '分類',
  parent_category: '父分類',
  sub_categories: '子分類',
  create_category: '新增分類',
  edit_category: '編輯分類',
  delete_category: '刪除分類',
  tags: '標籤',
  tag: '標籤',
  create_tag: '新增標籤',
  edit_tag: '編輯標籤',
  delete_tag: '刪除標籤',
  serial_numbers: '序號',
  serial_number: '序號',
  suffix: '後綴',
  low_serial_number: '低序號',
  high_serial_number: '高序號',
  batch_number: '批量編號',
  create_serial_number: '新增序號',
  edit_serial_number: '編輯序號',
  delete_serial_number: '刪除序號',
  registration_status: '註冊狀態',
  registered: '已註冊',
  unregistered: '未註冊',
  sort: '排序',
  color: '顏色',
  options: '選項',
  option: '選項',
  parent_option: '父選項',
  sub_options: '子選項',
  create_option: '新增選項',
  edit_option: '編輯選項',
  delete_option: '刪除選項',
  type: '類型',
  text: '文本',
  message: '信息',
  specifications: '規格',
  specification: '規格',
  create_specification: '新增規格',
  edit_specification: '編輯規格',
  delete_specification: '刪除規格',
  orders: '訂單',
  orders_management: '訂單管理',
  order: '訂單',
  edit_order: '編輯訂單',
  incomplete: '不完整',
  pending_payment: '待付款',
  being_prepared: '準備中',
  logistics_in_progress: '物流進行中',
  logistics_complete: '物流完成',
  completed: '完成',
  cancelled: '取消',
  expired: '過期',
  payment_method: '付款方式',
  shipping_method: '運輸方式',
  payment_price: '付款金額',
  order_sn: '訂單號 #',
  order_status: '訂單狀態',
  booking_note_sn: '托運單號 #',
  order_price: '訂單金額',
  shipping_fee: '運費',
  basic_shipping_fee: '基礎運費',
  shipping: '運輸',
  payment: '付款',
  shipping_sn: '運輸單號 #',
  payment_sn: '付款單號 #',
  third_party_sn: '第三方單號 #',
  recipient_name: '收件人姓名',
  recipient_phone: '收件人電話',
  recipient_email: '收件人Email',
  recipient_zipcode: '收件人郵政編碼',
  recipient_address: '收件人地址',
  store_sn: '店舖代號',
  store_name: '店舖名稱',
  store_phone: '店舖電話',
  store_address: '店舖地址',
  cvs_sn: '店舖寄貨編號',
  cvs_validation_code: '店舖寄貨驗證碼',
  atm_bank_code: 'ATM銀行代碼',
  atm_bank_account: 'ATM銀行帳號',
  atm_expired: 'ATM到期日期',
  item: '項目',
  qty: '數量',
  price_per_item: '單價',
  subtotal_price: '小計',
  coupons: '優惠券',
  coupon: '優惠券',
  discount: '折扣',
  shipping_management: '運輸管理',
  shipping_methods: '運輸方式',
  create_shipping_method: '新增運輸方式',
  edit_shipping_method: '編輯運輸方式',
  shipping_fee_list: '運費表',
  create_shipping_fee_list: '新增運費表',
  edit_shipping_fee_list_regions: '編輯運費表地區',
  edit_shipping_fee_list: '編輯運費表',
  delete_shipping_fee_list: '刪除運費表',
  region: '地區',
  regions: '地區',
  add_rows: '新增列',
  size: '尺寸(公分)',
  purchase_limit: '限購',
  launch_time: '發佈時間',
}
