import request from '@/utils/request'

export function getProductImages (data) {
  return request({
    url: '/api/products/images',
    method: 'post',
    data: data
  })
}

export function updateProductImages (data) {
  return request({
    url: '/api/products/images/update',
    method: 'post',
    data: data
  })
}
