import request from '@/utils/request'

export function getProductCategories (data) {
  return request({
    url: '/api/products/categories',
    method: 'post',
    data: data
  })
}

export function showProductCategory (data) {
  return request({
    url: '/api/products/categories/show',
    method: 'post',
    data: data
  })
}

export function storeProductCategory (data) {
  return request({
    url: '/api/products/categories/store',
    method: 'post',
    data: data
  })
}

export function updateProductCategory (data) {
  return request({
    url: '/api/products/categories/update',
    method: 'post',
    data: data
  })
}

export function updateProductCategorySort (data) {
  return request({
    url: '/api/products/categories/update_sort',
    method: 'post',
    data: data
  })
}

export function destroyProductCategory (data) {
  return request({
    url: '/api/products/categories/destroy',
    method: 'post',
    data: data
  })
}
