import request from '@/utils/request'

export function getOrders (data) {
  return request({
    url: '/api/orders',
    method: 'post',
    data: data
  })
}

export function showOrder (data) {
  return request({
    url: '/api/orders/show',
    method: 'post',
    data: data
  })
}

export function updateOrder (data) {
  return request({
    url: '/api/orders/update',
    method: 'post',
    data: data
  })
}
