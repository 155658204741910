import Cookies from 'js-cookie'

const sessionName = 'admin-session'

export function getSessionName () {
  return sessionName
}

export function getSession () {
  const session = Cookies.get(sessionName)
  return session ? session : ''
}

export function setSession (session) {
  return Cookies.set(sessionName, session)
}

export function removeSession () {
  return Cookies.remove(sessionName)
}
