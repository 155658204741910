import request from '@/utils/request'

export function getProductOptions (data) {
  return request({
    url: '/api/products/options',
    method: 'post',
    data: data
  })
}

export function showProductOption (data) {
  return request({
    url: '/api/products/options/show',
    method: 'post',
    data: data
  })
}

export function storeProductOption (data) {
  return request({
    url: '/api/products/options/store',
    method: 'post',
    data: data
  })
}

export function updateProductOption (data) {
  return request({
    url: '/api/products/options/update',
    method: 'post',
    data: data
  })
}

export function updateProductOptionSort (data) {
  return request({
    url: '/api/products/options/update_sort',
    method: 'post',
    data: data
  })
}

export function destroyProductOption (data) {
  return request({
    url: '/api/products/options/destroy',
    method: 'post',
    data: data
  })
}
