import * as productSerialNumbersApi from '@/api/products/productSerialNumbers'

const productSerialNumbers = {
  state: {
  },
  mutations: {
  },
  actions: {
    getProductSerialNumbers ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productSerialNumbersApi.getProductSerialNumbers(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showProductSerialNumber ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productSerialNumbersApi.showProductSerialNumber(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeProductSerialNumber ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productSerialNumbersApi.storeProductSerialNumber(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    batchProductSerialNumber ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productSerialNumbersApi.batchProductSerialNumber(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateProductSerialNumber ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productSerialNumbersApi.updateProductSerialNumber(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyProductSerialNumber ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productSerialNumbersApi.destroyProductSerialNumber(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default productSerialNumbers
