import * as productOptionsApi from '@/api/products/productOptions'

const productOptions = {
  state: {
  },
  mutations: {
  },
  actions: {
    getProductOptions ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productOptionsApi.getProductOptions(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showProductOption ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productOptionsApi.showProductOption(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeProductOption ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productOptionsApi.storeProductOption(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateProductOption ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productOptionsApi.updateProductOption(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateProductOptionSort ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productOptionsApi.updateProductOptionSort(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyProductOption ({ commit }, data) {
      return new Promise((resolve, reject) => {
        productOptionsApi.destroyProductOption(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default productOptions
