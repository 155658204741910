import request from '@/utils/request'

export function getPosts (data) {
  return request({
    url: '/api/posts',
    method: 'post',
    data: data
  })
}

export function showPost (data) {
  return request({
    url: '/api/posts/show',
    method: 'post',
    data: data
  })
}

export function storePost (data) {
  return request({
    url: '/api/posts/store',
    method: 'post',
    data: data
  })
}

export function updatePost (data) {
  return request({
    url: '/api/posts/update',
    method: 'post',
    data: data
  })
}

export function destroyPost (data) {
  return request({
    url: '/api/posts/destroy',
    method: 'post',
    data: data
  })
}
